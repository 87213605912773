import React, { useState, useEffect } from 'react';

const LoadingIndicator = ({ isLoading, isDarkMode }) => {
  const [activeCircle, setActiveCircle] = useState(0);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setActiveCircle((prev) => (prev + 1) % 3);
      }, 300);
      return () => clearInterval(interval);
    }
  }, [isLoading]);

  if (!isLoading) return null;

  const containerStyle = {
    position: 'absolute',
    bottom: '100%',
    left: '20%',
    transform: 'translateX(-50%)',
    backgroundColor: isDarkMode ? '#3A3A3A' : '#E0E0E0',
    borderRadius: '12px',
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '8px',
  };

  const circleStyle = (isActive) => ({
    width: '6px',
    height: '20px',
    borderRadius: '8px',
    backgroundColor: isActive 
      ? (isDarkMode ? '#E0E0E0' : '#333333')
      : (isDarkMode ? '#666666' : '#999999'),
    margin: '3px',
    transition: 'background-color 0.3s',
  });

  return (
    <div style={containerStyle}>
      {[0, 1, 2].map((index) => (
        <div
          key={index}
          style={circleStyle(index === activeCircle)}
        />
      ))}
    </div>
  );
};

export default LoadingIndicator;