import React, { useState, useRef, useEffect } from 'react';
import { Settings, Upload, Send, ChevronLeft, ChevronRight, Sun, Moon, HelpCircle, Plus, Paperclip, LogOut, FileText, Sparkle, Download, BookOpenText, CircleX, X, HelpingHand, CloudDownload } from 'lucide-react';
import { ThumbsUp, ThumbsDown, Copy, RefreshCw, ArrowUp, Octagon } from 'lucide-react';
import LoadingIndicator from './LoadingIndicator';
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './LoginButton';

// const API_ENDPOINT = "https://4b7665dd-98cb-47af-9795-d3d8ec0064a6-00-334wn4gxieb85.pike.replit.dev";

// const API_ENDPOINT = "http://localhost:5000/api/query";
// const FEEDBACK_ENDPOINT = "http://localhost:5000/api/feedback";
// const RECENT_CHATS_ENDPOINT = "http://localhost:5000/api/recent_chats";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://ragbot2-app-7a8e4854488e.herokuapp.com';

const API_ENDPOINT = `${API_BASE_URL}/api/query`;
const FEEDBACK_ENDPOINT = `${API_BASE_URL}/api/feedback`;
const RECENT_CHATS_ENDPOINT = `${API_BASE_URL}/api/recent_chats`;

const App = () => {
  const [message, setMessage] = useState("");
  const [conversation, setConversation] = useState([]);
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [currentQueryIndex, setCurrentQueryIndex] = useState(0);
  const [recentlyClicked, setRecentlyClicked] = useState({});
  const textareaRef = useRef(null);
  const conversationRef = useRef(null);
  const [recentChats, setRecentChats] = useState([]);
  const [pdfPages, setPdfPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pdfImage, setPdfImage] = useState(null);
  const [isRightSidebarVisible, setIsRightSidebarVisible] = useState(false);
  const [hasSystemOutput, setHasSystemOutput] = useState(false);
  const [isNewChat, setIsNewChat] = useState(true);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeDocumentId, setActiveDocumentId] = useState(null);
  const [idMapping, setIdMapping] = useState({});
  const [isMoreClicked, setIsMoreClicked] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [contextAlt, setContextAlt] = useState("");
  const [clickedIcons, setClickedIcons] = useState({});
  const settingsMenuRef = useRef(null);
  const settingsButtonRef = useRef(null);
  const popupRef = useRef(null);
  const [isQueryPending, setIsQueryPending] = useState(false);
  const [cancelToken, setCancelToken] = useState(null);
  const [isHelpPopupOpen, setIsHelpPopupOpen] = useState(false);
  const [helpPopupContent, setHelpPopupContent] = useState('');
  const [isHelpLoading, setIsHelpLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [pdfBase64, setPdfBase64] = useState(null);
  const [pdfPageNumber, setPdfPageNumber] = useState(1);
  const { isAuthenticated, isLoading2 } = useAuth0();
  const [canDownload, setCanDownload] = useState(false);
  const [downloadError, setDownloadError] = useState(null);

  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const presetQueries = [
    'Please be as specific as possible with your query.',
    'Please select words that are unambiguous.',
    'Recommend searching by study content instead of study author.',
    'Please click on the icon next to each sentence in the output to see the source document.',
    'Your query is not guaranteed to find the correct answer, but rather one that is semantically similar.',
    'Please click the dislike button to save the result of a poorly performing query for investigation.',
    'All data is stored on a database that will be vetted by IT before any official use.',
    'All current data in the knowledge center is publicly available.',
    'All data summarized by OpenAI may be used to train their AI models (is not the case when using the Enterprise API).',
    'Recommendations for (non-confidential) documents for inclusion should be sent to justin@aegentic.com.',
    'Pavonia does claim any ownership of any documents in the knowledge base.',
    'Uploaded documents will not be available until they have been vetted and processed.',
    'Please email Justin Yu (justin@aegentic.com) for any questions or concerns.'
  ];

  const HelpPopup = ({ isOpen, onClose, content, isDarkMode, isLoading }) => {
    const popupRef = useRef(null);
    const styles = getStyles(isDarkMode);
    const [clickedIcons, setClickedIcons] = useState({});
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          onClose();
        }
      };
  
      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen, onClose]);
  
    const handleCopy = (question, index) => {
      navigator.clipboard.writeText(question)
        .then(() => {
          console.log('Question copied to clipboard');
          setClickedIcons(prev => ({
            ...prev,
            [`copy-${index}`]: true
          }));
          setTimeout(() => {
            setClickedIcons(prev => ({
              ...prev,
              [`copy-${index}`]: false
            }));
          }, 300);
        })
        .catch(err => console.error('Failed to copy question: ', err));
    };
  
    const handleSend = (question) => {
      sendMessage(question);
      onClose();
    };
  
    if (!isOpen) return null;
  
    return (
      <div style={styles.contextPopupOverlay}>
        <div ref={popupRef} style={styles.contextPopup}>
          <div style={styles.contextPopupHeader}>
            <h2 style={styles.contextPopupTitle}>Potential Follow-up Questions</h2>
            <button style={styles.contextPopupCloseButton} onClick={onClose}>
              <X size={24} />
            </button>
          </div>
          <div style={styles.contextPopupContent}>
            {isLoading ? (
              <div style={styles.loadingContainer}>
                <LoadingIndicator isLoading={isLoading} isDarkMode={isDarkMode} />
                <p style={styles.loadingText}>Generating potential follow-up questions...</p>
              </div>
            ) : (
              content.map((question, index) => (
                <div key={index} style={styles.questionContainer}>
                  <p style={styles.contextPopupParagraph}>{question}</p>
                  <div style={styles.questionActions}>
                    <button
                      style={styles.actionButton(clickedIcons[`copy-${index}`])}
                      onClick={() => handleCopy(question, index)}
                      aria-label="Copy question"
                    >
                      <Copy size={16} />
                    </button>
                    <button
                      style={styles.actionButton(clickedIcons[`send-${index}`])}
                      onClick={() => handleSend(question)}
                      aria-label="Send question"
                    >
                      <Send size={16} />
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleMore = (index) => {
    setIsMoreClicked(prevState => !prevState);
    // Add your logic for handling the 'more options' click
    console.log('More options clicked for message at index', index);
  };  

  const toggleRightSidebar = () => {
    if (hasSystemOutput && !isNewChat) {
      setIsRightSidebarVisible(!isRightSidebarVisible);
    }
  };

  const sendFeedback = async (feedback, userQuery, systemOutput) => {
    try {
      const response = await fetch(FEEDBACK_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_query: userQuery,
          system_output: systemOutput,
          feedback: feedback
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log('Feedback sent successfully');
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const sliderRef = useRef(null);

  const handleDocumentClick = async (uniqueId, event) => {
    if (event) {
      event.stopPropagation();
    }
    setIsPopupOpen(false);
    setActiveDocumentId(uniqueId);
    setIsRightSidebarVisible(true);
    setIsLoading(true);
    setCanDownload(false);  // Reset download state
  
    try {
      console.log(`Fetching document details for UniqueId: ${uniqueId}`);
      console.log(`API_BASE_URL: ${API_BASE_URL}`);
      const response = await fetch(`${API_BASE_URL}/api/get_document_details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uniqueId: uniqueId, idMapping: idMapping }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Received document details:", data);
  
      if (data.status === 'success' && data.pdfBase64) {
        console.log(`Received PDF base64 data. Length: ${data.pdfBase64.length}`);
        setPdfBase64(data.pdfBase64);
        setFileName(data.filename);
        setCurrentPage(data.pageNumber);
        setPdfPageNumber(data.pageNumber);
        setCanDownload(true);  // Enable download button
      } else {
        console.error('Error or missing PDF data:', data.message || 'No error message provided');
        setPdfBase64(null);
        setFileName(null);
        setCurrentPage(null);
        setPdfPageNumber(1);
        setCanDownload(false);
      }
    } catch (error) {
      console.error('Error retrieving document details:', error);
      setPdfBase64(null);
      setFileName(null);
      setCurrentPage(null);
      setPdfPageNumber(1);
      setCanDownload(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    if (!canDownload || !fileName || !pdfBase64) {
      console.error('Cannot download: missing fileName or PDF data');
      setDownloadError('Unable to download PDF: missing data');
      return;
    }
  
    try {
      // Decode the base64 string to binary
      const binaryString = window.atob(pdfBase64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
  
      // Create a Blob from the bytes
      const blob = new Blob([bytes], { type: 'application/pdf' });
  
      // Create a link and trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      // Clean up the URL object
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setDownloadError(`Error downloading PDF: ${error.message}`);
    }
  };

  const renderMessageContent = (text) => {
    const parts = text.split(/(\(Source: [^\)]+\))/g);
    return parts.map((part, index) => {
      if (part.startsWith('(Source:')) {
        const sourceId = part.match(/\(Source: ([^\)]+)\)/)[1];
        return (
          <FileText
            key={index}
            size={16}
            onClick={() => handleDocumentClick(sourceId)}
            style={{
              color: activeDocumentId === sourceId ? '#FF79C6' : '#3498DB',
              cursor: 'pointer',
              marginLeft: '4px',
              marginRight: '4px',
              verticalAlign: 'middle'
            }}
          />
        );
      }
      return part;
    });
  };

  const getIconColor = (sourceId) => {
    if (activeDocumentId === sourceId) {
      return isDarkMode ? '#FF79C6' : '#bd93f9';
    }
    return isDarkMode ? '#3498DB' : '#2980B9';
  };

  const handlePageChange = (direction) => {
    setCurrentPage((prev) => {
      const newPage = prev + direction;
      return Math.max(0, Math.min(newPage, pdfPages.length - 1));
    });S
  };

  const formatMessageWithCitations = (text) => {
    if (text.trim().toLowerCase() === "No results found.") {
      return <p>{text}</p>;
    }

    const paragraphs = text.split('\n\n');
    return paragraphs.map((paragraph, pIndex) => {
      const parts = paragraph.split(/(\(Source: [^\)]+\))/g);
      return (
        <p key={pIndex} style={{ marginBottom: '1em' }}>
          {parts.map((part, index) => {
            if (part.startsWith('(Source:')) {
              const sourceId = part.match(/\(Source: ([^\)]+)\)/)[1];
              return (
                <React.Fragment key={index}>
                  {' '}
                  <FileText
                    size={16}
                    onClick={(event) => handleDocumentClick(sourceId, event)}
                    style={{
                      color: getIconColor(sourceId),
                      cursor: 'pointer',
                      marginLeft: '-4px',
                      marginRight: '0px',
                      verticalAlign: 'middle'
                    }}
                  />
                </React.Fragment>
              );
            }
            return part;
          })}
        </p>
      );
    });
  };

  const handlePrevQueries = () => {
    setCurrentQueryIndex((prevIndex) => 
      (prevIndex - 1 + presetQueries.length) % presetQueries.length
    );
  };

  const handleNextQueries = () => {
    setCurrentQueryIndex((prevIndex) => 
      (prevIndex + 1) % presetQueries.length
    );
  };

  const visibleBoxes = 4;
  const boxWidth = 180; // Width of each box including margin

  const handleScroll = (direction) => {
    setCurrentQueryIndex((prevIndex) => {
      const newIndex = (prevIndex + direction + presetQueries.length) % presetQueries.length;
      return newIndex;
    });
  };

  const getVisibleQueries = () => {
    const queries = [];
    for (let i = 0; i < visibleBoxes; i++) {
      const index = (currentQueryIndex + i) % presetQueries.length;
      queries.push(presetQueries[index]);
    }
    return queries;
  };

  // Function to get the circular index
  const getCircularIndex = (index) => {
    return (index + presetQueries.length) % presetQueries.length;
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      sendMessage();
    }
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    // Create SVG favicon
    const svgString = `
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#BD93F9" stroke="#BD93F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="M12 2L15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2z"/>
      </svg>
    `;
    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);

    // Set favicon
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = url;
    } else {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = url;
      document.head.appendChild(newLink);
    }

    // Clean up
    return () => URL.revokeObjectURL(url);
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, [message]);

  // USE THIS ONE WHEN READY
  // const sendMessage = () => {
  //   if (message.trim() !== "") {
  //     setConversation([...conversation, { text: message, from: "user" }]);
  //     setMessage("");
  //   }
  // };

  const scrollToBottom = () => {
    if (conversationRef.current) {
      const scrollHeight = conversationRef.current.scrollHeight;
      const height = conversationRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      conversationRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation]); // This will trigger scrollToBottom whenever the conversation updates

  useEffect(() => {
    fetchRecentChats();
  }, []);

  const fetchRecentChats = async () => {
    try {
      const response = await fetch(RECENT_CHATS_ENDPOINT);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setRecentChats(data);
    } catch (error) {
      console.error("Error fetching recent chats:", error);
    }
  };

  const handleRecentChatClick = (query) => {
    setMessage(query);
    sendMessage(query);
  };

  const startNewChat = () => {
    setConversation([]);
    setHasSystemOutput(false);
    setIsRightSidebarVisible(false);
    setIsNewChat(true);
    setPdfImage(null);
  };

  const ContextPopup = ({ isOpen, onClose, context, isDarkMode, handleDocumentClick }) => {
    const popupRef = useRef(null);
    const styles = getStyles(isDarkMode);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          onClose();
        }
      };
  
      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen, onClose]);
  
    if (!isOpen) return null;
  
    return (
      <div style={styles.contextPopupOverlay}>
        <div ref={popupRef} style={styles.contextPopup}>
          <div style={styles.contextPopupHeader}>
            <h2 style={styles.contextPopupTitle}>Details</h2>
            <button style={styles.contextPopupCloseButton} onClick={onClose}>
              <X size={24} />
            </button>
          </div>
          <div style={styles.contextPopupContent}>
            {context.split('\n\n\n').map((paragraph, index) => (
              <p key={index} style={styles.contextPopupParagraph}>
                {paragraph.split(/(\(Source: [^\)]+\))/g).map((part, partIndex) => {
                  if (part.startsWith('(Source:')) {
                    const sourceId = part.match(/\(Source: ([^\)]+)\)/)[1];
                    return (
                      <FileText
                        key={partIndex}
                        size={16}
                        onClick={(event) => handleDocumentClick(sourceId, event)}
                        style={{
                          ...styles.contextPopupSourceIcon,
                          cursor: 'pointer',
                        }}
                      />
                    );
                  }
                  return part;
                })}
              </p>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const sendMessage = async (userMessage) => {
    const messageToSend = userMessage || message;
  
    // If a query is already pending or the message is empty, prevent sending another query
    if (isQueryPending || messageToSend.trim() === "") return;
  
    setIsQueryPending(true); // Set query state to pending
    setMessage(""); // Clear the input field immediately after submission
    setIsNewChat(false); // Indicate that a new chat session has started
    setIsWaitingForResponse(true);
  
    // Add the user's message to the conversation
    setConversation(prev => [...prev, { text: messageToSend, from: "user" }]);
  
    try {
      const controller = new AbortController();
      setCancelToken(controller);
  
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: messageToSend }),
        signal: controller.signal, // Attach the signal to the fetch request for cancellation
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
  
      // Add the bot's response to the conversation
      setConversation(prev => [...prev, { text: data.response, from: "bot" }]);
      setHasSystemOutput(true);
      setIdMapping(data.idMapping);
      setContextAlt(data.context_alt);
  
      // Fetch updated recent chats after sending a message
      fetchRecentChats();
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Query was cancelled');
      } else {
        console.error("Error:", error);
        const errorMessage = `Error: ${error.message}`;
        setConversation(prev => [...prev, { text: errorMessage, from: "bot" }]);
        setHasSystemOutput(true);
      }
    } finally {
      setIsQueryPending(false); // Allow new queries after the response is received
      setCancelToken(null);
      setIsWaitingForResponse(false);
    }
  };

  const cancelQuery = () => {
    if (cancelToken) {
      cancelToken.abort();
      setIsQueryPending(false);
      setCancelToken(null);
    }
  };

  const SystemMessage = ({ message }) => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '5px',
      }}>
        <div style={{
          flexShrink: 0,
          marginRight: '10px',
          marginTop: '5px',
        }}>
          <Sparkle size={30} style={{ color: isDarkMode ? '#FF79C6' : '#BD93F9' }} />
        </div>
        <div style={{
          ...styles.botMessage,
          flex: 1,
        }}>
          {formatMessageWithCitations(message)}
        </div>
      </div>
    );
  };

  const regenerateResponse = async (userMessage, index) => {
    setIsWaitingForResponse(true);
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: userMessage }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      // Update only the bot's response in the conversation
      setConversation(prev => {
        const newConversation = [...prev];
        newConversation[index] = { text: data.response, from: "bot" };
        return newConversation;
      });

      setIdMapping(data.idMapping);
      setContextAlt(data.context_alt);
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = `Error: ${error.message}`;
      setConversation(prev => {
        const newConversation = [...prev];
        newConversation[index] = { text: errorMessage, from: "bot" };
        return newConversation;
      });
    } finally {
      setIsWaitingForResponse(false);
    }
  };

  const generateFollowUpQuestions = async () => {
    setIsHelpLoading(true);
    setIsHelpPopupOpen(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/generate_questions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ context: contextAlt }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // Remove any numbering from the beginning of the questions
      const cleanedQuestions = data.questions.map(question => 
        question.replace(/^\d+\.\s*/, '').trim()
      );
      setHelpPopupContent(cleanedQuestions);
    } catch (error) {
      console.error("Error generating follow-up questions:", error);
      setHelpPopupContent(["Error generating questions. Please try again."]);
    } finally {
      setIsHelpLoading(false);
    }
  };

  const handleActionButton = (action, index) => {
    setClickedIcons(prev => ({
      ...prev,
      [`${action}-${index}`]: true
    }));

    switch (action) {
      case 'help':
        generateFollowUpQuestions();
        break;
      case 'more':
        setIsPopupOpen(true);
        break;
      case 'like':
        console.log(`Liked message at index ${index}`);
        sendFeedback('good', conversation[index - 1].text, conversation[index].text);
        break;
      case 'dislike':
        console.log(`Disliked message at index ${index}`);
        sendFeedback('bad', conversation[index - 1].text, conversation[index].text);
        break;
      case 'copy':
        navigator.clipboard.writeText(conversation[index].text)
          .then(() => console.log('Text copied to clipboard'))
          .catch(err => console.error('Failed to copy text: ', err));
        break;
      case 'regenerate':
        const userMessage = conversation[index - 1].text;
        sendMessage(userMessage);
        break;
      default:
        break;
    }

    setTimeout(() => {
      setClickedIcons(prev => ({
        ...prev,
        [`${action}-${index}`]: false
      }));
    }, 300);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const handleLike = (index) => {
    console.log(`Liked message at index ${index}`);
  };

  const handleDislike = (index) => {
    console.log(`Disliked message at index ${index}`);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => console.log('Text copied to clipboard'))
      .catch(err => console.error('Failed to copy text: ', err));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isSettingsOpen &&
          settingsMenuRef.current &&
          !settingsMenuRef.current.contains(event.target) &&
          !settingsButtonRef.current.contains(event.target)) {
        setIsSettingsOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSettingsOpen]);

  const handleRegenerate = (index) => {
    console.log(`Regenerating message at index ${index}`);
  };

  const fileInputRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handlePaperclipClick = () => {
    fileInputRef.current.click();
  };

  // Dynamically generate styles based on the current theme
  const getStyles = (isDark) => ({
    '@import': "url('https://fonts.googleapis.com/css2?family=Aptos:wght@400;700&display=swap')",
    '@global': {
      'body': {
        margin: 0,
        padding: 0,
        overflow: 'hidden',
      },
      '#root': {
        height: '100vh',
        width: '100vw',
      },
    },
    container: {
      display: 'flex',
      height: '100vh',
      width: '100vw',
      color: isDark ? '#E0E0E0' : '#333333',
      backgroundColor: isDark ? '#1E1E1E' : '#FFFFFF',
      fontFamily: "'Aptos', sans-serif",
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
      left: 0,
    },  
    sidebar: {
      width: '260px',
      height: '100%',
      paddingTop: '60px',
      paddingLeft: '20px',
      paddingRight: '20px',
      backgroundColor: isDark ? '#252525' : '#F0F0F0',
      overflowY: 'auto',
      transition: 'transform 0.3s ease-in-out',
      transform: isSidebarVisible ? 'translateX(0)' : 'translateX(-100%)',
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 10,
      boxSizing: 'border-box',
    },
    // mainContentWrapper: {
    //   flex: 1,
    //   display: 'flex',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   height: '100%',
    //   transition: 'padding-left 0.3s ease-in-out',
    //   paddingLeft: isSidebarVisible ? '300px' : '0',
    // },
    mainContentWrapper: {
      display: 'flex',
      flex: 1,
      height: '100%',
      transition: 'margin-left 0.3s ease-in-out, margin-right 0.3s ease-in-out',
      marginLeft: isSidebarVisible ? '260px' : '0',
      marginRight: isRightSidebarVisible ? '40%' : '0',
    },
    chatColumn: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      padding: '0 20px',
      boxSizing: 'border-box',
      transition: 'margin 0.3s ease-in-out',
    },
    pdfViewerColumn: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '10px',
      boxSizing: 'border-box',
      overflowY: 'auto',
      height: '100%', // Ensure it takes full height of the parent
    },
    pdfContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100%', // Ensure it takes full height of the parent
    },
    pdfViewer: {
      width: '95%',
      height: '85%', // Subtract space for file name
      border: 'none',
    },
    pdfInfo: {
      width: '100%',
      padding: '10px 0',
    },
    pdfImage: {
      maxWidth: '100%',
      maxHeight: '80vh',
      objectFit: 'contain',
      marginTop: '50px',
    },
    mainContent: {
      width: '100%',
      maxWidth: '800px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      boxSizing: 'border-box',
    },
    sidebarToggle: {
      position: 'absolute',
      left: isSidebarVisible ? '260px' : '0',
      top: '50%',
      transform: 'translateY(-50%)',
      backgroundColor: isDark ? '#3498DB' : '#2980B9',
      color: 'white',
      border: 'none',
      padding: '5px', // Reduced padding to make the button smaller
      cursor: 'pointer',
      transition: 'left 0.3s ease-in-out',
      zIndex: 20,
      borderRadius: '10%', // Make the button circular
      width: '24px', // Set a fixed width
      height: '24px', // Set a fixed height
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sidebarTitle: {
      fontSize: '1.8em',
      fontWeight: 'bold',
      marginTop: '30px',
      marginBottom: '30px',
      color: isDark? '#FF79C6' : '#bd93f9',
    },
    sidebarTitle2: {
      fontSize: '1em',
      fontWeight: 'bold',
      marginBottom: '60px',
      color: isDark ? '#3498DB' : '#2980B9',
    },
    sidebarSection: {
      marginBottom: '20px',
    },
    sidebarSectionTitle: {
      fontSize: '1.0em',
      color: isDark ? '#BDBDBD' : '#666666',
      marginBottom: '10px',
    },
    sidebarItem: {
      fontSize: '0.9em',
      color: isDark ? '#9E9E9E' : '#333333',
      marginBottom: '5px',
      cursor: 'pointer',
      transition: 'color 0.3s',
      '&:hover': {
        color: isDark ? '#E0E0E0' : '#000000',
      },
    },
    rightSidebar: {
      width: '40%',
      height: '100%',
      backgroundColor: isDark ? '#252525' : '#F0F0F0',
      position: 'fixed',
      top: 0,
      right: isRightSidebarVisible ? 0 : '-40%',
      transition: 'right 0.3s ease-in-out',
      overflowY: 'auto',
      boxShadow: isDark ? '-2px 0 5px rgba(0,0,0,0.5)' : '-2px 0 5px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '60px', // Add padding to avoid overlapping with the settings icon
    },
    rightSidebarToggle: {
      position: 'fixed',
      right: isRightSidebarVisible ? '40%' : '0',
      top: '50%',
      transform: 'translateY(-50%)',
      backgroundColor: isDark ? '#3498DB' : '#2980B9',
      color: 'white',
      border: 'none',
      padding: '5px',
      cursor: 'pointer',
      transition: 'right 0.3s ease-in-out',
      zIndex: 1001,
      borderRadius: '10%',
      width: '24px',
      height: '24px',
      display: hasSystemOutput && !isNewChat ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pdfImageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '20px 0',
    },
    actionButton: (isClicked) => ({
      backgroundColor: isClicked ? '#3498DB' : 'transparent',
      border: 'none',
      color: isClicked ? 'white' : (isDark ? '#9E9E9E' : '#666666'),
      cursor: 'pointer',
      marginLeft: '10px',
      padding: '5px',
      borderRadius: '5px',
      transition: 'background-color 0.3s, color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#3A3A3A' : '#E0E0E0',
        color: isDark ? '#E0E0E0' : '#333333',
      },
    }),
    addWorkspaceButton: {
      fontSize: '1em',
      color: isDark ? '#9E9E9E' : '#333333',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: '5px 10px',
      borderRadius: '5px',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#333333' : '#E0E0E0',
      },
    },
    questionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '5px',
    },
    questionActions: {
      display: 'flex',
      gap: '10px',
    },
    actionButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: isDark ? '#9E9E9E' : '#666666',
      padding: '5px',
      borderRadius: '5px',
      transition: 'background-color 0.3s, color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#3A3A3A' : '#E0E0E0',
        color: isDark ? '#E0E0E0' : '#333333',
      },
    },
    uploadIcon: {
      marginBottom: '30px',
      color: isDark ? '#3498DB' : '#2980B9',
    },
    loadingMessage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      fontSize: '1.2em',
      color: isDark ? '#E0E0E0' : '#333333',
    },
    queryGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '15px',
      marginBottom: '30px',
      width: '100%',
      maxWidth: '700px',
    },
    queryButton: {
      backgroundColor: isDark ? '#2C2C2C' : '#E0E0E0',
      border: `1px solid ${isDark ? '#3A3A3A' : '#CCCCCC'}`,
      color: isDark ? '#E0E0E0' : '#333333',
      padding: '12px',
      borderRadius: '12px',
      textAlign: 'left',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#3A3A3A' : '#D0D0D0',
      },
    },
    paperclipButton: {
      position: 'absolute',
      left: '5px',
      bottom: '8px',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: isDark ? '#c5c5d2' : '#6e6e80',
      padding: '8px', // Add some padding for easier clicking
    },
    warningMessage: {
      textAlign: 'center',
      fontSize: '0.8em',
      color: isDark ? '#9E9E9E' : '#666666',
      marginTop: '10px',
    },
    warningMessage2: {
      textAlign: 'center',
      fontSize: '1.0em',
      color: isDark ? '#9E9E9E' : '#666666',
      marginTop: '10px',
      marginBottom: '10px',
    },
    warningMessage3: {
      textAlign: 'center',
      fontSize: '1.0em',
      color: isDark ? '#9E9E9E' : '#666666',
      marginTop: '20px',
      marginBottom: '10px',
    },
    draggingOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(16, 163, 127, 0.1)',
      borderRadius: '12px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.2em',
      color: '#10a37f',
      pointerEvents: 'none',
    },
    fileInputContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    fileInput: {
      display: 'none',
    },
    fileInputLabel: {
      backgroundColor: isDark ? '#2C2C2C' : '#E0E0E0',
      color: isDark ? '#E0E0E0' : '#333333',
      padding: '10px 15px',
      borderRadius: '5px 0 0 5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#3A3A3A' : '#D0D0D0',
      },
    },
    uploadButton: {
      backgroundColor: isDark ? '#3498DB' : '#2980B9',
      color: 'white',
      border: 'none',
      padding: '10px 15px',
      borderRadius: '0 5px 5px 0',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#2980B9' : '#3498DB',
      },
    },
    conversation: {
      flex: 1,
      overflowY: 'auto',
      padding: '20px',
      marginBottom: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      scrollBehavior: 'smooth', // Add smooth scrolling
    },
    message: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '15px',
      maxWidth: '70%',
    },
    userMessage: {
      alignSelf: 'flex-end',
      backgroundColor: isDark ? '#3498DB' : '#2980B9',
      color: 'white',
      padding: '10px 15px',
      borderRadius: '15px 15px 0 15px',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
    },
    botMessage: {
      alignSelf: 'flex-start',
      backgroundColor: isDark ? '#2C2C2C' : '#E0E0E0',
      color: isDark ? '#E0E0E0' : '#333333',
      padding: '0px 18px',
      borderRadius: '15px 15px 15px 0',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
    },
    inputArea: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 20px 20px',
      position: 'relative',
    },
    inputWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      flex: 1,
      padding: '16px',
      paddingLeft: '25px', // Make room for the paperclip button
      paddingRight: '50px', // Make room for the send button
      backgroundColor: isDark ? '#2C2C2C' : '#F0F0F0',
      color: isDark ? '#E0E0E0' : '#333333',
      border: '1px solid ' + (isDark ? '#3A3A3A' : '#CCCCCC'),
      borderRadius: '24px', // Rounded corners on all sides
      fontSize: '1em',
      '&:focus': {
        outline: 'none',
        boxShadow: `0 0 0 2px ${isDark ? '#3498DB' : '#2980B9'}`,
      },
    },
    sendButton: {
      position: 'absolute',
      right: '14px',
      bottom: '9px',
      width: '32px',
      height: '32px',
      backgroundColor: isQueryPending ? (isDark ? '#FF79C6' : '#bd93f9') : (isDark ? '#3498DB' : '#2980B9'),
      color: 'white',
      border: 'none',
      borderRadius: isQueryPending ? '12px' : '50%',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'background-color 0.3s, border-radius 0.3s',
      '&:hover': {
        backgroundColor: isQueryPending ? (isDark ? '#FF79C6' : '#bd93f9') : (isDark ? '#2980B9' : '#3498DB'),
      },
    },
    settingsButton: {
      position: 'absolute',
      top: '20px',
      right: '30px',
      backgroundColor: 'transparent',
      border: 'none',
      color: isDark ? '#E0E0E0' : '#333333',
      cursor: 'pointer',
      '&:hover': {
        color: isDark ? '#3498DB' : '#2980B9',
      },
    },
    settingsMenu: {
      position: 'absolute',
      top: '60px',
      right: '20px',
      backgroundColor: isDark ? '#252525' : '#F0F0F0',
      border: `1px solid ${isDark ? '#3A3A3A' : '#CCCCCC'}`,
      borderRadius: '16px',
      padding: '10px',
      zIndex: 1000,
    },
    settingsItem: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: isDark ? '#3A3A3A' : '#E0E0E0',
      },
    },
    settingsItemText: {
      marginLeft: '10px',
    },
    newChatButton: {
      position: 'absolute',
      left: '20px',
      top: '20px',
      width: '30px',
      height: '30px',
      backgroundColor: isDark ? '#3498DB' : '#2980B9',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'background-color 0.3s',
      zIndex: 1000,
      '&:hover': {
        backgroundColor: isDark ? '#2980B9' : '#3498DB',
      },
    },
    newChatButtonHidden: {
      position: 'absolute',
      left: '20px',
      top: '20px',
      width: '30px',
      height: '30px',
    },
    newChatButtonText: {
      marginLeft: '5px',
    },
    sidebarHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
    },
    messageActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '5px',
    },
    loadingIndicatorContainer: {
      position: 'absolute',
      left: '30px', // Align with the left side of the input box
      bottom: '100%', // Position it just above the input area
      marginBottom: '10px', // Add some margin between the indicator and the input area
    },
    loadingSpinner: {
      width: '30px', // Increase the width
      height: '30px', // Increase the height
      border: `3px solid ${isDark ? '#3498DB' : '#2980B9'}`,
      borderTop: '3px solid transparent',
      borderRadius: '50%',
      animation: 'spin 1s linear infinite',
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
    downloadButton: {
      position: 'absolute',
      top: '20px',
      left: '20px',
      backgroundColor: 'transparent',
      border: 'none',
      color: isDark ? '#E0E0E0' : '#333333',
      cursor: 'pointer',
      padding: '5px',
      transition: 'color 0.3s',
      zIndex: 1001,
      '&:hover': {
        color: isDark ? '#3498DB' : '#2980B9',
      },
    },
    actionButton: {
      backgroundColor: 'transparent',
      border: 'none',
      color: isDark ? '#9E9E9E' : '#666666',
      cursor: 'pointer',
      marginLeft: '10px',
      padding: '5px',
      borderRadius: '5px',
      transition: 'background-color 0.3s, color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#3A3A3A' : '#E0E0E0',
        color: isDark ? '#E0E0E0' : '#333333',
      },
    },
    loadingContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      minHeight: '150px', // Ensure a minimum height for the loading content
    },
    loadingSpinner: {
      animation: 'spin 1s linear infinite',
      marginBottom: '15px',
      color: isDark ? '#E0E0E0' : '#333333',
    },
    loadingText: {
      textAlign: 'center',
      color: isDark ? '#E0E0E0' : '#333333',
      fontSize: '18px',
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
    queryContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '20px',
      position: 'relative',
      width: '100%',
      maxWidth: `${boxWidth * visibleBoxes}px`,
    },
    queriesWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: `${boxWidth * visibleBoxes}px`,
      overflow: 'hidden',
    },
    queriesSlider: {
      display: 'flex',
      transition: 'transform 0.3s ease-in-out',
    },
    queryButton: {
      backgroundColor: isDark ? '#2C2C2C' : '#E0E0E0',
      border: `1px solid ${isDark ? '#3A3A3A' : '#CCCCCC'}`,
      color: isDark ? '#E0E0E0' : '#333333',
      padding: '10px',
      textAlign: 'center',
      cursor: 'initial',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#3A3A3A' : '#D0D0D0',
      },
      width: `${boxWidth - 20}px`,  // 20px for margins
      height: '110px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '12px',
      margin: '0 10px',
      flexShrink: 0,
      fontSize: '14px',
    },
    navButton: {
      backgroundColor: 'transparent',
      border: 'none',
      color: isDark ? '#E0E0E0' : '#333333',
      cursor: 'pointer',
      fontSize: '24px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      '&:hover': {
        color: isDark ? '#3498DB' : '#2980B9',
      },
      zIndex: 1,
    },
    leftNavButton: {
      left: '-40px',
    },
    rightNavButton: {
      right: '-40px',
    },
    placeholderText: {
      color: isDark ? '#E0E0E0' : '#333333',
      fontSize: '1.2em',
    },
    placeholderText: {
      color: isDark ? '#E0E0E0' : '#333333',
      fontSize: '0.9em',
    },
    pdfInfo: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    fileName: {
      fontSize: '14px',
      marginBottom: '0px',
      color: isDark ? '#E0E0E0' : '#333333',
      textAlign: 'center',
    },
    pageNum: {
      fontSize: '14px',
      marginBottom: '20px',
      color: isDark ? '#E0E0E0' : '#333333',
      textAlign: 'center',
    },
    securityNote: {
      textAlign: 'center',
      fontSize: '0.8em',
      color: isDark ? '#9E9E9E' : '#666666',
      marginTop: '10px',
    },
    contextPopupOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    contextPopup: {
      backgroundColor: isDark ? '#2C2C2C' : '#FFFFFF',
      borderRadius: '10px',
      width: '70%',
      height: '85%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      border: '2px solid #3498DB',
    },
    contextPopupHeader: {
      position: 'sticky',
      top: 0,
      backgroundColor: isDark ? '#2C2C2C' : '#FFFFFF',
      padding: '10px',
      paddingLeft: '20px',
      borderBottom: '1px solid #3498DB',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 1001,
    },
    contextPopupTitle: {
      color: isDark ? '#E0E0E0' : '#333333',
      fontSize: '20px',
      fontWeight: 'bold',
      margin: 0,
    },
    contextPopupCloseButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: '#3498DB',
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contextPopupContent: {
      padding: '10px 20px',
      color: isDark ? '#E0E0E0' : '#333333',
      flex: 1,
      overflowY: 'auto',
    },
    contextPopupParagraph: {
      marginBottom: '10px',
    },
    contextPopupSourceIcon: {
      color: '#3498DB',
      marginLeft: '4px',
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    questionActions: {
      display: 'flex',
      gap: '2px', // Decreased spacing between icons
    },
    loginContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
      backgroundColor: isDark ? '#1E1E1E' : '#FFFFFF',
    },
    loginTitle: {
      fontSize: '2.5em',
      marginBottom: '30px',
      color: isDark ? '#E0E0E0' : '#333333',
      textAlign: 'center',
    },
    pavoniaText: {
      color: isDark ? '#FF79C6' : '#bd93f9',
    },
    loginButtonContainer: {
      marginTop: '20px',
    },
    topRightIcons: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      display: 'flex',
      alignItems: 'center',
      zIndex: 1000,
    },
    iconButton: {
      backgroundColor: 'transparent',
      border: 'none',
      color: isDark ? '#E0E0E0' : '#333333',
      cursor: 'pointer',
      marginLeft: '15px',
      padding: '5px',
      transition: 'color 0.3s',
      '&:hover': {
        color: isDark ? '#3498DB' : '#2980B9',
      },
    },
    actionButton: (isClicked) => ({
      backgroundColor: isClicked ? '#3498DB' : 'transparent', // Blue when clicked
      border: 'none',
      color: isClicked ? 'white' : (isDark ? '#9E9E9E' : '#666666'),
      cursor: 'pointer',
      marginLeft: '10px',
      padding: '5px',
      borderRadius: '5px',
      transition: 'background-color 0.3s, color 0.3s',
      '&:hover': {
        backgroundColor: isClicked ? '#3498DB' : 'transparent',
        color: isDark ? '#E0E0E0' : '#333333',
      },
    })
  });

  const styles = getStyles(isDarkMode, isSidebarVisible);
  styles.sidebarItem = {
    ...styles.sidebarItem,
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: isDarkMode ? '#3A3A3A' : '#E0E0E0',
    },
  };

  if (isLoading2) {
    return <div style={styles.loadingOverlay}>Loading...</div>;
  }

  return (
    <div style={styles.container}>
      {!isAuthenticated ? (
        <div style={styles.loginContainer}>
          <h1 style={styles.loginTitle}>
            Welcome to <span style={styles.pavoniaText}>PAVONIA</span>
          </h1>
          <div style={styles.loginButtonContainer}>
            <LoginButton isDarkMode={isDarkMode} />
          </div>
        </div>
      ) : (
        <>
          {/* Sidebar */}
          <div style={styles.sidebar}>
            <h2 style={styles.sidebarTitle}>PAVONIA</h2>
            <h2 style={styles.sidebarTitle2}>Internal Knowledge Center</h2>
            <button style={styles.newChatButton} onClick={startNewChat} aria-label="New Chat">
              <Plus size={24} />
            </button>
            <div style={styles.sidebarSection}>
              <h3 style={styles.sidebarSectionTitle}>Recent Chats</h3>
              {recentChats.map((chat, index) => (
                <p 
                  key={chat._id} 
                  style={{...styles.sidebarItem, cursor: 'pointer'}}
                  onClick={() => handleRecentChatClick(chat.query)}
                >
                  {truncateText(chat.query, 30)}
                </p>
              ))}
            </div>
          </div>

          <HelpPopup
            isOpen={isHelpPopupOpen}
            onClose={() => setIsHelpPopupOpen(false)}
            content={helpPopupContent}
            isDarkMode={isDarkMode}
            isLoading={isHelpLoading}
          />
      
          <ContextPopup
            isOpen={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
            context={contextAlt}
            isDarkMode={isDarkMode}
            handleDocumentClick={handleDocumentClick}
          />
      
          <button style={styles.sidebarToggle} onClick={toggleSidebar}>
            {isSidebarVisible ? <ChevronLeft /> : <ChevronRight />}
          </button>
      
          {!isSidebarVisible && (
            <button 
              style={{...styles.newChatButton, ...styles.newChatButtonHidden}} 
              onClick={startNewChat} 
              aria-label="New Chat"
            >
              <Plus size={24} />
            </button>
          )}
      
          {/* Loading overlay */}
          {isLoading && (
            <div style={styles.loadingOverlay}>
              <div style={styles.spinner}></div>
            </div>
          )}
      
          {/* Main Content Wrapper */}
          <div style={styles.mainContentWrapper}>
            <div style={styles.chatColumn}>
              {conversation.length === 0 ? (
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                  <div style={styles.warningMessage2}>
                    Answers to frequently-asked questions:
                  </div>
                  <div style={styles.queryContainer}>
                    <button onClick={() => handleScroll(-1)} style={{...styles.navButton, ...styles.leftNavButton}}>
                      <ChevronLeft />
                    </button>
                    <div style={styles.queriesWrapper}>
                      <div style={styles.queriesSlider}>
                        {getVisibleQueries().map((query, index) => (
                          <button 
                            key={index}
                            style={styles.queryButton}
                          >
                            {query}
                          </button>
                        ))}
                      </div>
                    </div>
                    <button onClick={() => handleScroll(1)} style={{...styles.navButton, ...styles.rightNavButton}}>
                      <ChevronRight />
                    </button>
                  </div>
                </div>
              ) : (
                <div ref={conversationRef} style={styles.conversation}>
                  {conversation.map((c, index) => (
                    <div key={index} style={{
                      ...styles.message,
                      alignSelf: c.from === 'user' ? 'flex-end' : 'flex-start',
                      maxWidth: c.from === 'user' ? '70%' : '100%',
                    }}>
                      {c.from === 'user' ? (
                        <div style={styles.userMessage}>
                          {c.text}
                        </div>
                      ) : (
                        <SystemMessage message={c.text} handleDocumentClick={handleDocumentClick} activeDocumentId={activeDocumentId} />
                      )}
                      {c.from === 'bot' && (
                        <div style={styles.messageActions}>
                          <button
                            key="help"
                            style={{
                              ...styles.actionButton(clickedIcons[`help-${index}`]),
                              marginRight: '0px'
                            }}
                            onClick={() => handleActionButton('help', index)}
                            aria-label="Help"
                          >
                            <HelpingHand size={20} />
                          </button>
                          <button
                            key="more"
                            style={{
                              ...styles.actionButton(clickedIcons[`more-${index}`]),
                              marginRight: '10px'
                            }}
                            onClick={() => handleActionButton('more', index)}
                            aria-label="More options"
                          >
                            <BookOpenText size={18} />
                          </button>
                          {['like', 'dislike', 'copy', 'regenerate'].map((action) => (
                            <button
                              key={action}
                              style={styles.actionButton(clickedIcons[`${action}-${index}`])}
                              onClick={() => handleActionButton(action, index)}
                              aria-label={action.charAt(0).toUpperCase() + action.slice(1)}
                            >
                              {action === 'like' && <ThumbsUp size={16} />}
                              {action === 'dislike' && <ThumbsDown size={16} />}
                              {action === 'copy' && <Copy size={16} />}
                              {action === 'regenerate' && <RefreshCw size={16} />}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div style={styles.inputArea}>
                <div 
                  style={styles.inputWrapper}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <div style={styles.loadingIndicatorContainer}>
                    {(isLoading || isHelpLoading || isWaitingForResponse) && 
                      <LoadingIndicator isLoading={isLoading || isHelpLoading || isWaitingForResponse} isDarkMode={isDarkMode} />}
                  </div>
                  <textarea
                    ref={textareaRef}
                    value={message}
                    onChange={handleMessageChange}
                    onKeyDown={handleKeyPress}
                    style={{
                      ...styles.input,
                      fontFamily: "'Aptos', sans-serif",
                      resize: 'none',
                      overflowY: 'hidden',
                      minHeight: '24px',
                      maxHeight: '150px',
                      padding: '12px 50px 0px 25px',
                      lineHeight: '1.5',
                    }}
                    placeholder="Send a message..."
                    rows={1}
                  />
                  {/* <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={styles.fileInput}
                  />
                  {/* <button onClick={handlePaperclipClick} style={styles.paperclipButton}>
                    <Paperclip size={20} />
                  </button> */}
                  <button 
                    onClick={isQueryPending ? cancelQuery : () => sendMessage()} 
                    style={styles.sendButton}
                  >
                    {isQueryPending ? <Octagon size={18} /> : <ArrowUp size={18} />}
                  </button>
                  {isDragging && (
                    <div style={styles.draggingOverlay}>
                      Drop file here
                    </div>
                  )}
                </div>
                <div style={styles.warningMessage}>
                  Please verify all outputs by reviewing the source document(s).
                </div>
              </div>
            </div>
          </div>
      
          {/* Right Sidebar */}
          <div style={styles.rightSidebar}>
            <div style={styles.pdfViewerColumn}>
              {canDownload && (
                <button
                  onClick={handleDownload}
                  style={styles.downloadButton}
                  aria-label="Download PDF"
                >
                  <CloudDownload size={24} />
                </button>
              )}
              {isLoading ? (
                <div style={styles.loadingMessage}>Loading PDF...</div>
              ) : pdfBase64 ? (
                <div style={styles.pdfContainer}>
                  <iframe
                    src={`data:application/pdf;base64,${encodeURIComponent(pdfBase64)}#page=${pdfPageNumber}`}
                    width="100%"
                    height="100%"
                    style={styles.pdfViewer}
                    title="PDF Viewer"
                  />
                  <div style={styles.pdfInfo}>
                    <p style={styles.fileName}>File name: {fileName}</p>
                    {currentPage && <p style={styles.pageNum}>Page: {currentPage}</p>}
                  </div>
                </div>
              ) : (
                <div style={styles.placeholderText}>Please click on a document icon to view the source PDF.</div>
              )}
            </div>
          </div>
      
          {/* Right Sidebar Toggle Button */}
          {hasSystemOutput && !isNewChat && !isPopupOpen && !isHelpPopupOpen && (
            <button style={styles.rightSidebarToggle} onClick={toggleRightSidebar}>
              {isRightSidebarVisible ? <ChevronRight /> : <ChevronLeft />}
            </button>
          )}
      
          {/* Settings Button and Menu
          <button 
            ref={settingsButtonRef}
            style={styles.settingsButton} 
            onClick={toggleSettings} 
            aria-label="Settings"
          >
            <Settings size={24} />
          </button>
          {isSettingsOpen && (
            <div ref={settingsMenuRef} style={styles.settingsMenu}>
              <div style={styles.settingsItem} onClick={toggleDarkMode}>
                {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
                <span style={styles.settingsItemText}>
                  {isDarkMode ? 'Light Mode' : 'Dark Mode'}
                </span>
              </div>
            </div>
          )} */}
        
        {/* Top Right Icons */}
        <div style={styles.topRightIcons}>
          <button
            style={styles.iconButton}
            onClick={toggleDarkMode}
            aria-label={isDarkMode ? "Light Mode" : "Dark Mode"}
          >
            {isDarkMode ? <Sun size={24} /> : <Moon size={24} />}
            </button>
            <button
              style={styles.iconButton}
              onClick={handleLogout}
              aria-label="Logout"
            >
              <LogOut size={24} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default App;
